<template>

	<div class="result-table-item bookmark-wrapper">
		<div
			class="result-table-body"
			v-for="(item, i) in tempbookmark"
			:key="`${i}-${item.id}`"
			:id="'id-' + item.id"
		>

			<!--Row Starts-->
			<div class="result-table-row">

				<!--Text Job title -->
				<div class="result-table-cell flex-basis-50">
					<div class="center-wrapper">

						<nuxt-link
							:to="localePath({
								name: 'job-finden-job-stellenangebotId-jobTitel',
								params: {
									stellenangebotId: item.stellenangebotId,
									jobTitel: item.jobTitel.replace(/\s+|[,\/]/g, '-').toLowerCase()
								}
							})"
							class="result-table-item-header"
						>
							{{ item.jobTitel }}
						</nuxt-link>

						<media :query="{maxWidth: 680}">
							<p
								v-for="(item, jobOrte) in item.jobOrte"
								:key="jobOrte"
							>
								{{ item.jobOrt }}
								({{ item.jobRegion }})
							</p>
						</media>

					</div>
				</div>

				<media :query="{minWidth: 770}">
					<!--Text jobOrte -->
					<div class="result-table-cell flex-basis-50">
						<div class="center-wrapper">
							<p
								v-for="(item, jobOrte) in item.jobOrte"
								:key="jobOrte"
							>
								{{ item.jobOrt }}
								({{ item.jobRegion }})
							</p>
						</div>
					</div>
				</media>

				<!--Button action Bookmark -->
				<div class="result-table-cell flex-basis-5 action-cell">
					<button
						class="btn action"
						@click="removeOneFromBookmark(item)"
					>
						<font-awesome-icon
							:icon="['fal', 'trash']"
						/>
						<span class="button-text">
							{{ ButtonDelete }}
						</span>
					</button>
				</div>

			</div>
			<!--Row Ends-->
		</div>
	</div>
</template>

<script>
import Media from 'vue-media';
import { mapState } from "vuex";

export default {
    name: "BookmarkItem",
    components: {
		Media
    },
	data() {
        return {
            Agreement: 'nach Vereinbarung',
            ButtonDelete: 'Löschen',
        };
    },
	computed: {
		...mapState(["tempbookmark", "navMain"]),
 	},
	methods: {
		removeOneFromBookmark(item) {
			this.$store.commit('removeOneFromBookmark', item)
		},
	},
}
</script>
<style lang="scss">
 .bookmark-wrapper {
	.result-table-cell {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
 }
</style>
