<template>
	<div>
		<multiselect
			placeholder="Umkreis"
			:options="orderByOptions.map(orderByOptions => orderByOptions.value)"
			v-model="selected"
			:searchable="false"
			class="select-distance"
		>
		</multiselect>
	</div>
</template>

<script>
import {
	mapState
} from 'vuex';

export default {
	data() {
		return {
			selected: [],
			orderByOptions: [{
					text: '5 km',
					value: '5',
				},
				{
					text: '10 km',
					value: '10',
				},
				{
					text: '25 km',
					value: '25',
				},
				{
					text: '50 km',
					value: '50',
				},
			],
		};
	},
	computed: {
		...mapState([
			'filterRadius',
		]),
	},
	watch: {
		selected(newVal) {
			let selected;
			selected = (typeof (newVal) !== 'string' || !newVal) ? null : this.selected; /* null to make clear it was removed */

			const payload = {
				selected: selected,
			};
			this.$store.dispatch('setFilterDistance', payload);
		},
		filterRadius() {
			this.selected = [];
		},
	},
}
</script>

<style lang="scss">

	.multiselect--active .multiselect__placeholder {
		display: block !important;
	}

	.select-distance {

		.multiselect__single {
			&::after {
				@extend .span_after;
			}
		}
		.multiselect__element .multiselect__option span {
			&::after {
				@extend .span_after;
			}
		}

		.span_after {
			content: "km";
			position: absolute;
			margin-left: 5px;
		}
	}
</style>
