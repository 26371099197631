<template>
	<section class="p-0 mt-3 page--wrapper mb-7">
		<div class="container">
			<div class="result-table-wrapper" v-if="tempbookmark.length">
				<div class="table-headers">
					<div class="d-flex justify-content-between align-items-center w-100">
						<header class="mt-4 mb-3 h4">
							<span>
								{{ ButtonBookmarklist }}
							</span>
						</header>

						<div class="action-cell flex-basis-5">
							<nuxt-link exact to="/bewerber/jobsuche/jobboerse" class="btn action">
								<font-awesome-icon
									:icon="['fal', 'search']"
								/>
								<span class="button-text">
									{{ ButtonBack }}
								</span>
							</nuxt-link>
						</div>

					</div>
				</div>
			</div>
			<BookmarkItem
				:tempbookmark="tempbookmark"
				v-if="tempbookmark.length"
			/>
			<div class="mt-5 no-data-msg" v-else>
				<div class="alert alert-primary rounded-0" role="alert">
  					{{ AlertText }}
				</div>
				<nuxt-link exact to="/bewerber/jobsuche/jobboerse" class="btn btn-primary">
					{{ ButtonBackAlert }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script>
import BookmarkItem from "./BookmarkItem.vue";
import { mapState } from "vuex";

export default {
	layout: 'default',
	components: {
		BookmarkItem
	},
	data() {
		return {
			ButtonBack: 'Jobsuche',
			ButtonBackAlert: 'Zurück',
			ButtonBookmarklist: 'Ihre Merkliste',
			AlertText: 'Sie haben bisher keine Stellen auf Ihrer Merkliste!'
		};
	},
	computed: {
		...mapState(['navMain', 'tempbookmark']),
	}
};
</script>
