<template>
	<div class="multislect-wrapper">
		<!-- {{facet.field}} -->
		<multiselect
			placeholder="Arbeitsbereich"
			:limit-text="displayText"
			:options="options.map(options => options.text)"
			v-model="selected"
			:multiple="true"
			:close-on-select="false"
			:clear-on-select="false"
			:searchable="false"
			:limit="1"
		>
		</multiselect>
	</div>

</template>

<script>
import { mapState } from 'vuex';
import MultiselectCheckboxes from '~/components/atoms/forms/select/MultiselectCheckboxes';
export default {
	name: 'VolumeFacet',
	components: {
		MultiselectCheckboxes
	},
	props: [
		'facet'
	],
	data() {
		return {
			selected: [],
			value: [],
				options: [...(this.facet.values.map(item => ({
				text: `${item.value}`,
				value: item.value,
				count: item.count,
			}))).sort((a, b) => a.value - b.value)],
		};
	},
	watch: {
		selected() {
			const payload = {
				facet: this.facet.field,
				selected: this.selected,
			};
			this.$store.dispatch('setFilter', payload);
		},
		filters() {
			if (!this.filters[this.facet.field]) {
				this.selected = [];
			}
		}
	},
	computed: {
		...mapState([
			'filters'
		]),
	},
  	methods: {
		displayText(count) {
			if (count === 3 ) {
				return this.value[0]
			}
			else {
				return count  + ' + mehr'
			}
		},
	}
};
</script>
<style lang="scss">
@import '~@/assets/scss/common';
	.form-group {
		margin-top: 20px;
	}
	.checkbox-label {
 	 	display: block;
	}
	.list-group-item {
		border: none;
	}
	.multiselect--above .multiselect__content-wrapper {
		top: 100%;
	}
	.multiselect__input, .multiselect__single {
		background: transparent;
		margin-bottom: 0;
		margin-left: 5px;
		color: $primary;

		padding: 0;

		&::placeholder {
			color: $isd--blue-light;
		}
	}
</style>
