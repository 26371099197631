<template>
	<b-form-group>
		<b-list-group>
			<b-list-group-item>{{facet.field}}</b-list-group-item>
			<b-list-group-item>
				<b-form-group :id="'dropdown-'+facet.field">
					<b-form-select
						:id="facet.field"
						v-model="selected"
						:options="options"
					></b-form-select>
				</b-form-group>
			</b-list-group-item>
	 	</b-list-group>
	</b-form-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: [
	'facet',
  ],
  data() {
	return {
		selected: [],
			options: [...(this.facet.values.map(item => ({
			text: `${item.value}`,
			value: item.value,
			count: item.count,
		}))).sort((a, b) => a.value - b.value)],
	};
  },
  watch: {
	selected(newVal) {
		if (typeof (newVal) !== 'string' || !newVal) return;
		const payload = {
			facet: this.facet.field,
			selected: this.selected,
		};
		this.$store.dispatch('setFilter', payload);
	},
	filters() {
		if (!this.filters[this.facet.field]) {
			this.selected = [];
		}
	},
  },
  computed: {
	...mapState([
		'filters',
	]),
  },
};
</script>
<style>
  .form-group {
	margin-top: 20px;
  }
  .list-group-item {
	border: none;
  }
</style>
