var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result-table-item bookmark-wrapper"},_vm._l((_vm.tempbookmark),function(item,i){return _c('div',{key:`${i}-${item.id}`,staticClass:"result-table-body",attrs:{"id":'id-' + item.id}},[_c('div',{staticClass:"result-table-row"},[_c('div',{staticClass:"result-table-cell flex-basis-50"},[_c('div',{staticClass:"center-wrapper"},[_c('nuxt-link',{staticClass:"result-table-item-header",attrs:{"to":_vm.localePath({
							name: 'job-finden-job-stellenangebotId-jobTitel',
							params: {
								stellenangebotId: item.stellenangebotId,
								jobTitel: item.jobTitel.replace(/\s+|[,\/]/g, '-').toLowerCase()
							}
						})}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.jobTitel)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('media',{attrs:{"query":{maxWidth: 680}}},_vm._l((item.jobOrte),function(item,jobOrte){return _c('p',{key:jobOrte},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.jobOrt)+"\n\t\t\t\t\t\t\t("+_vm._s(item.jobRegion)+")\n\t\t\t\t\t\t")])}),0)],1)]),_vm._v(" "),_c('media',{attrs:{"query":{minWidth: 770}}},[_c('div',{staticClass:"result-table-cell flex-basis-50"},[_c('div',{staticClass:"center-wrapper"},_vm._l((item.jobOrte),function(item,jobOrte){return _c('p',{key:jobOrte},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.jobOrt)+"\n\t\t\t\t\t\t\t("+_vm._s(item.jobRegion)+")\n\t\t\t\t\t\t")])}),0)])]),_vm._v(" "),_c('div',{staticClass:"result-table-cell flex-basis-5 action-cell"},[_c('button',{staticClass:"btn action",on:{"click":function($event){return _vm.removeOneFromBookmark(item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}}),_vm._v(" "),_c('span',{staticClass:"button-text"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.ButtonDelete)+"\n\t\t\t\t\t")])],1)])],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }