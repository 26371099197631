<template>

	<div class="applicant-form">
		<div class="pb-2">

			<FormulateForm
				class="row gy-1 gx-4"
				v-model="formValues"
				@submit="submitHandler"
			>

			<FormulateInput
				name="anrede"
				type="select"
				label="Anrede*"
				placeholder="Anrede"
				class="col-md-6"
				:options="[
					{ value: 'Frau', label: 'Frau' },
					{ value: 'Herr', label: 'Herr' },
					{ value: '', label: 'divers'}
				]"
				validation="required"
			/>

			<FormulateInput
				name="vorname"
				type="text"
				label="Vorname*"
				placeholder="Vorname"
				class="col-md-6"
				validation="required"
			/>

			<FormulateInput
				name="nachname"
				type="text"
				label="Nachname*"
				placeholder="Nachname"
				class="col-md-6"
				validation="required"
			/>

			<FormulateInput
				label="Geburtsdatum*"
				language="de"
				name="date"
				type="date"
				validation="required"
				class="col-md-6"
			/>

			<FormulateInput
				name="strasse"
				type="text"
				label="Straße"
				placeholder="Straße"
				class="col-md-6"
			/>

			<FormulateInput
				name="hausnummer"
				type="text"
				label="Hausnummer"
				placeholder="Hausnummer"
				class="col-md-6"
			/>

			<FormulateInput
				name="plz"
				type="text"
				label="PLZ*"
				placeholder="PLZ"
				class="col-md-4"
				validation="required"
			/>

			<FormulateInput
				name="wohnort"
				type="text"
				label="Wohnort*"
				placeholder="Wohnort"
				class="col-md-4"
				validation="required"
			/>

			<FormulateInput
				name="land"
				type="select"
				label="Land*"
				placeholder="bitte Land auswählen"
				class="col-md-4"
				:options="country"
				validation="required"
			/>

			<FormulateInput
				name="email"
				type="email"
				label="E-Mail*"
				placeholder="E-Mail"
				class="col-md-6"
				validation="required"
			/>

			<FormulateInput
				name="telPrefix"
				type="text"
				label="Vorwahl*"
				placeholder="Vorwahl"
				class="col-md-2"
				validation="required"
			/>

			<FormulateInput
				name="telNumber"
				type="text"
				label="Telefonnummer*"
				placeholder="Telefonnummer"
				class="col-md-4"
				validation="required"
			/>


			<FormulateInput
				name="gehaltswunsch"
				type="text"
				label="Gehaltswunsch"
				placeholder="Gehaltswunsch"
				class="col-md-6"
			/>

			<!-- @INFO: For more information on uploading and processing the files
				https://vueformulate.com/guide/inputs/types/file/#uploader
			-->
			<FormulateInput
				type="file"
				name="cv"
				label="Anschreiben/Lebenslauf"
				help="Anschreiben/Lebenslauf in PDF Format"
				validation="mime:application/pdf"
				multiple
			/>

			<FormulateInput
				type="file"
				name="others"
				label="Sonstiges"
				help="Zeugnisse / Zertifikate / Qualifikationen / Sonstiges in PDF Format"
				validation="mime:application/pdf"
				multiple
			/>

			 <FormulateInput
			 		name="datenschutzbestimmung"
					type="checkbox"
					validation="required"
				>
					<template #label="{ id }">
					<label :for="id">
						<span class="ps-2"> Ich habe die <nuxt-link to="/datenschutz.html" title="Zur Datenschutzbestimmung" target="_blank">Datenschutzbestimmung</nuxt-link> gelesen und akzeptiere diese.*</span>
					</label>
				</template>
			</FormulateInput>

			 <FormulateInput
					name="einwilligung"
					type="checkbox"
				>
					<template #label="{ id }">
					<label :for="id">
						<span class="ps-2"> Ich stimme der <nuxt-link to="/datenschutzerklaerung-unternehmensverbund.html" title="Zur Datenschutzbestimmung" target="_blank">Einwilligung zur Datenerhebung</nuxt-link> im Unternehmensverbund zu.</span>
					</label>
				</template>
			</FormulateInput>

			<FormulateInput
				type="submit"
			>
				<span
					class="formulate-input-element--submit--label"
					id="button-formular-stellenanzeigen-senden"
				>
					Bewerbung absenden
				</span>
			</FormulateInput>

			</FormulateForm>

		</div>
	</div>

</template>

<script>
import jobApplicationMixin from '@/mixins/job-application';
export default {
	mixins: [jobApplicationMixin],
	methods: {
		async submitHandler() {
			if (this.selectedJobOffer) {
				const jobOfferDetails = this.datajobs.find(j => j.stellenangebotId === this.selectedJobOffer)
				// if exists, convert files for upload
				const cvs = await this.convertAttachments(this.formValues.cv);
				const others = await this.convertAttachments(this.formValues.others);
				const attachments = [
					...cvs,
					...others
				];

				const data = {
					salutation: this.formValues.anrede ? this.formValues.anrede.value : '',
					firstName: this.formValues.vorname,
					lastName: this.formValues.nachname,
					dateOfBirth: this.formValues.date,
					contractType: jobOfferDetails.jobVertragsArt,
					profession: jobOfferDetails.jobUmfaenge && jobOfferDetails.jobUmfaenge.length ? jobOfferDetails.jobUmfaenge[0].jobUmfangName : '',
					telephoneAreaCode: this.formValues.telPrefix,
					telephoneNumber: this.formValues.telNumber,
					referenzNummer: jobOfferDetails.referenzNummer,
					// gewuenschterArbeitsort: '',
					subsidiaryId: jobOfferDetails.nlCode,
					streetName: this.formValues.strasse,
					streetNumber: this.formValues.hausnummer,
					zipCode: this.formValues.plz,
					city: this.formValues.wohnort,
					country: this.formValues.land ? this.formValues.land.value : '',
					email: this.formValues.email,
					salaryExpectation: this.formValues.gehaltswunsch,
					dataPrivacyAccepted: this.formValues.einwilligung,
					attachments,
				};
				try {
					await this.$api.jobApplication.send(data);
					window.location.href = `/danke.html`;
				} catch (error) {
					this.$toast('Es ist ein Fehler aufgetreten.', {
						timeout: 2000
					});
					console.log(error);
				}
			} else {
				this.$toast('Bitte wählen Sie ein Stellenangebot aus.', {
					timeout: 2000
				});
			}
		}
	}
}
</script>
<style lang="scss">
	@import '~@/assets/scss/common';
	.applicant-form {
		.formulate-input[data-classification='group']
		.formulate-input-group-item {
			padding-left: 1rem;
		}
		.formulate-input[data-classification='box']
		.formulate-input-label {
			font-weight: 300;
			cursor: pointer;
		}
		.formulate-input[data-classification='box'] .formulate-input-element {
			@include media-breakpoint-down(md) {
				width: 30px;
			}
		}
		.vdp-datepicker__calendar .cell.selected {
			background: $isd--blue-dark;
			color: #fff;
		}
		@include media-breakpoint-down(md) {
			span {
				display: block;
				font-size: 1rem;
			}
		}
	}
</style>
