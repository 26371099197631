<template>
	<div class="multislect-wrapper">
		<!-- {{facet.field}} -->
		<multiselect
			placeholder="Art der Anstellung"
			value="Art der Anstellung"
			:limit-text="displayText"
			:options="options.map(options => options.text)"
			v-model="selected"
			:multiple="true"
			:close-on-select="false"
			:clear-on-select="false"
			:searchable="false"
			:limit="1"
		>
		</multiselect>
	</div>

</template>
<script>
import { mapState } from 'vuex';

export default {
	name: 'WorkspaceFacet',
		props: [
		'facet'
	],
	data() {
		return {
			selected: [],
			value: [],
				options: [...(this.facet.values.map(item => ({
				text: `${item.value}`,
				value: item.value,
				count: item.count,
			}))).sort((a, b) => a.value - b.value)],
		};
	},
	watch: {
		selected() {
			const payload = {
				facet: this.facet.field,
				selected: this.selected,
			};
			this.$store.dispatch('setFilter', payload);
		},
		filters() {
			if (!this.filters[this.facet.field]) {
				this.selected = [];
			}
		}
	},
	computed: {
		...mapState([
			'filters'
		]),
	},
  	methods: {
		displayText(count) {
			if (count === 3 ) {
				return this.value[0]
			}
			else {
				return count  + ' + mehr'
			}
		}
	}
}
</script>
<style lang="scss">
	.multiselect--above .multiselect__content-wrapper {
		position: static;
		top: 100%;
	}
</style>
