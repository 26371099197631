<template>
	<div class="abstarct-background">
		<div class="container">
			<div class="footer-abstarct-wrapper">
				<div
					class="row d-flex justify-content-center align-items-center md-pt-2"
				>
					<div class="col-md-9">
						<header class="footer-abstarct-header">
							{{ InitiativeHeader }}
						</header>
						<p>
							{{ InitiativeText }}
						</p>
					</div>
					<div class="col-md-3">
						<nuxt-link
							exact
							to="/bewerber/jobsuche/initiativbewerbung"
							class="btn btn-secondary ps-4 pe-4 w-100"
							id="button-bewerben-initiativbewerbung"
						>
							{{ InitiativeButton }}
						</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			InitiativeHeader: 'Initiativbewerbung',
			InitiativeText:
				'Die richtige Stelle ist nicht dabei? Bewirb Dich initiativ!',
			InitiativeButton: 'Initiativ bewerben',
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.footer-abstarct-wrapper {
	@include media-breakpoint-down(md) {
		padding: 25px 15px;
	}
}

.footer-abstarct-header {
	@include responsive-type(3, 1.5);
	color: $primary;
}
</style>
